var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container", class: { connecting: !_vm.isDiscovered } },
    [
      _c(
        "transition",
        { attrs: { name: "connect-fade", mode: "out-in" } },
        [
          !_vm.isDiscovered
            ? _c("ConnectForm", {
                key: "1",
                attrs: { skipLogin: _vm.skipLogin },
              })
            : _c("IDE", { key: "2" }),
        ],
        1
      ),
      _vm._l(_vm.modals, function (modal) {
        return [
          _c(
            modal.component,
            _vm._g(
              _vm._b(
                {
                  key: modal.id,
                  tag: "component",
                  on: {
                    closed: function ($event) {
                      return _vm.hideModal(modal)
                    },
                  },
                },
                "component",
                modal.props,
                false
              ),
              modal.events
            )
          ),
        ]
      }),
      _c("vue-snotify"),
      _vm.tourType
        ? _c("Tour", {
            model: {
              value: _vm.tourType,
              callback: function ($$v) {
                _vm.tourType = $$v
              },
              expression: "tourType",
            },
          })
        : _vm._e(),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeRequests > 0,
              expression: "activeRequests > 0",
            },
          ],
          attrs: { id: "activeRequests" },
        },
        [_c("i", { staticClass: "fas fa-spinner fa-spin fa-2x" })]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }