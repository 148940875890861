var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("div", { staticClass: "row help" }, [
        _c("i", { staticClass: "fas fa-check-circle" }),
        _vm.terms && _vm.privacy
          ? _c("span", [
              _vm._v("By clicking the button below, you agree to our "),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.terms.href,
                    title: _vm.terms.title,
                    target: "_blank",
                  },
                },
                [_vm._v("Terms of Service")]
              ),
              _vm._v(" and that you have read our "),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.privacy.href,
                    title: _vm.privacy.title,
                    target: "_blank",
                  },
                },
                [_vm._v("Privacy Policy")]
              ),
              _vm._v("."),
            ])
          : _vm.terms
          ? _c("span", [
              _vm._v("By clicking the button below, you agree to our "),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.terms.href,
                    title: _vm.terms.title,
                    target: "_blank",
                  },
                },
                [_vm._v("Terms of Service")]
              ),
              _vm._v("."),
            ])
          : _vm.privacy
          ? _c("span", [
              _vm._v(
                "By clicking the button below, you agree that you have read our "
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.privacy.href,
                    title: _vm.privacy.title,
                    target: "_blank",
                  },
                },
                [_vm._v("Privacy Policy")]
              ),
              _vm._v("."),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }