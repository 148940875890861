// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Ubuntu&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-size: 14px;
  scroll-behavior: smooth;
}
body, input, textarea, button, select, datalis {
  font-family: "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#ide .logo {
  align-content: center;
}
#ide .logo h2 {
  flex: 1;
  text-align: center;
  font-weight: bold !important;
  font-size: 32px !important;
}
#ide .logo h2 .version {
  font-weight: normal;
  display: none;
}
#ide header.navbar::after {
  content: "";
  background: linear-gradient(270deg, #77cc09, #fc0, #77cc09);
  background-size: 200% 100%;
  animation: 3s linear infinite page--header-grad;
  position: absolute;
  height: 6px;
  left: 0;
  right: 0;
  bottom: 0;
}
#menu li .dropdown {
  top: 72px !important;
  z-index: 999 !important;
}
@keyframes page--header-grad {
0%, 100% {
    background-position: 0 100%;
}
50% {
    background-position: 100% 100%;
}
}
a {
  font-weight: bold;
}
.discovery-info strong {
  color: #0A4393 !important;
}
.help.info, .message.info {
  background-color: #77cc09;
  border: none;
  color: white;
}
.help.info, .message.info a {
  color: white;
  text-decoration: underline;
}
.vue-component.model-builder .block_collection .blockTitle {
  background-color: #0A4393;
  color: white;
}
.vue-component.model-builder .block_collection {
  border-color: #0A4393;
}
.vue-component.model-builder .inout .inputs div, .vue-component.model-builder .inout .outputs div {
  margin: 0.25rem 0;
}
html, body, .container {
  height: 100%;
  overflow: hidden;
}
body, input, textarea, button, select, datalist {
  font-family: "Ubuntu", sans-serif;
}
body {
  margin: 0;
}
a {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  color: black;
}
ul, ol {
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-top: 0;
}
button, input[type=submit], input[type=reset] {
  margin: 1px;
  background-color: #eee;
}
input, textarea, select, datalist, button {
  border: 1px solid #aaa;
  border-radius: 2px;
  padding: 2px 6px;
}
input:hover, textarea:hover, select:hover, datalist:hover, button:hover {
  border-color: #000;
}
input:hover:disabled, textarea:hover:disabled, select:hover:disabled, datalist:hover:disabled, button:hover:disabled {
  border-color: #aaa;
}
button:focus, input:focus, textarea:focus, select:focus, datalist:focus, .multiselect:focus-within {
  outline: none;
  box-shadow: 0 0 3px rgba(22, 102, 182, 0.75);
}
.multiselect input:focus {
  box-shadow: 0 0 0;
}
button:disabled i {
  color: #aaa;
}
.sepr {
  margin-right: 0.5em;
}
.sepl {
  margin-left: 0.5em;
}
.boolean .fa-check-circle {
  color: green;
}
.boolean .fa-times-circle {
  color: red;
}
.boolean .fa-question-circle {
  color: #555;
}
tt, .vue-component.styled-description code, p code {
  color: maroon;
  display: inline-block;
  padding: 0 0.1em 0 0.3em;
}
kbd {
  margin: 0px 0.1em;
  padding: 0.2em 0.6em 0.1em 0.6em;
  border-radius: 3px;
  border: 1px solid rgb(204, 204, 204);
  color: rgb(51, 51, 51);
  line-height: 1.33em;
  font-size: 0.9em;
  display: inline-block;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2), inset 0px 0px 0px 2px #ffffff;
  background-color: rgb(247, 247, 247);
  text-shadow: 0 1px 0 #fff;
}
#activeRequests {
  display: inline-block;
  width: 2em;
  height: 2em;
  padding: 0.5em;
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 2em;
  z-index: 9999;
}
.connecting #activeRequests {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}
.vue-component h2 {
  font-size: 1.75em;
  padding: 0.25em 0 0.25em 0;
  margin: 0 0 0.75em 0;
  border-bottom: 1px solid #ccc;
}
.vue-component h3 {
  font-size: 1.4em;
  margin: 1.5em 0 0.75em 0;
  padding: 0.25em 0 0.25em 0;
  border-bottom: 1px dotted #ccc;
}
.vue-component h4 {
  font-size: 1.2em;
}
.vue-component .tabular {
  margin: 0.5em 0;
}
.vue-component .tabular .value ul {
  padding-left: 20px;
}
.container {
  width: 100%;
  height: 100%;
}
.container .snotifyToast__title {
  font-size: 1em;
  font-weight: bold;
}
.container .snotifyToast__body {
  font-size: 0.9em;
}
p.aboutPage {
  line-height: 1.33em;
  text-align: justify;
  margin-top: 0;
}
h3.aboutPage {
  margin-bottom: 0.5em;
}
.no-data, .log-empty {
  text-align: center;
  margin: 1em;
  font-weight: bold;
}
.help, .message {
  display: flex;
}
.help span, .message span {
  display: block;
  flex-grow: 1;
}
.help .fas, .message .fas {
  display: block;
  height: 100%;
  margin-right: 0.5em;
  margin-top: 1px;
}
.help.info, .message.info {
  border: 1px solid #aaa;
  background-color: #eee;
  color: #555;
}
.help.warning, .message.warning {
  border: 1px solid #f9d67a;
  background-color: #fbeabc;
  color: #795600;
}
.help.error, .message.error {
  border: 1px solid #f97a7a;
  background-color: #fbbcbc;
  color: #790000;
}
.message {
  padding: 0.5em;
  margin-bottom: 1em;
  border-radius: 0.5em;
}
.help {
  margin: 0.75em 0.5em;
}
.help span {
  font-size: 0.9em;
}

/* Transition */
.connect-fade-enter-active, .connect-fade-leave-active {
  transition: opacity 0.3s ease;
}
.connect-fade-enter, .connect-fade-leave-to {
  opacity: 0;
}

/* General editor styling */
.sourceHeader {
  padding: 5px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
}
.sourceHeader strong {
  display: block;
  margin: auto 0;
  flex-grow: 1;
}
.sourceToolbar {
  text-align: right;
}
.tabContent > .textEditor {
  border: 0 !important;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
