// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media only screen and (min-height: 600px) {
#credentials.tabs {
		min-height: 250px;
}
}
#credentials.tabs .tabsHeader {
	margin-bottom: 0.5em;
}
#credentials.tabs {
	display: flex;
}
#credentials.tabs .tabsBody, #credentials.tabs .tabContent, #credentials.tabs form, #credentials.tabs form > div:last-child  {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}
#credentials.tabs form > div {
	flex-grow: 1;
}
#credentials.tabs form > div.bottom {
	justify-content: flex-end;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
