// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body[data-v-1cc0c864] {
  font-size: 14px;
  scroll-behavior: smooth;
}
body[data-v-1cc0c864], input[data-v-1cc0c864], textarea[data-v-1cc0c864], button[data-v-1cc0c864], select[data-v-1cc0c864], datalis[data-v-1cc0c864] {
  font-family: "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#ide .logo[data-v-1cc0c864] {
  align-content: center;
}
#ide .logo h2[data-v-1cc0c864] {
  flex: 1;
  text-align: center;
  font-weight: bold !important;
  font-size: 32px !important;
}
#ide .logo h2 .version[data-v-1cc0c864] {
  font-weight: normal;
  display: none;
}
#ide header.navbar[data-v-1cc0c864]::after {
  content: "";
  background: linear-gradient(270deg, #77cc09, #fc0, #77cc09);
  background-size: 200% 100%;
  animation: 3s linear infinite page--header-grad-1cc0c864;
  position: absolute;
  height: 6px;
  left: 0;
  right: 0;
  bottom: 0;
}
#menu li .dropdown[data-v-1cc0c864] {
  top: 72px !important;
  z-index: 999 !important;
}
@keyframes page--header-grad-1cc0c864 {
0%, 100% {
    background-position: 0 100%;
}
50% {
    background-position: 100% 100%;
}
}
a[data-v-1cc0c864] {
  font-weight: bold;
}
.discovery-info strong[data-v-1cc0c864] {
  color: #0A4393 !important;
}
.help.info[data-v-1cc0c864], .message.info[data-v-1cc0c864] {
  background-color: #77cc09;
  border: none;
  color: white;
}
.help.info[data-v-1cc0c864], .message.info a[data-v-1cc0c864] {
  color: white;
  text-decoration: underline;
}
.vue-component.model-builder .block_collection .blockTitle[data-v-1cc0c864] {
  background-color: #0A4393;
  color: white;
}
.vue-component.model-builder .block_collection[data-v-1cc0c864] {
  border-color: #0A4393;
}
.vue-component.model-builder .inout .inputs div[data-v-1cc0c864], .vue-component.model-builder .inout .outputs div[data-v-1cc0c864] {
  margin: 0.25rem 0;
}
.connectPage[data-v-1cc0c864] {
  background-color: #0A4393;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  height: 100%;
}
.connectPage .logo[data-v-1cc0c864] {
  max-width: 500px;
  margin-bottom: 1rem;
}
.connectPage .logo img[data-v-1cc0c864] {
  max-height: 150px;
}
.connectContainer[data-v-1cc0c864] {
  width: 500px;
  background-color: white;
  border: 1px solid black;
  border-radius: 3em;
  padding: 2.5em 3em;
  font-family: "Ubuntu", sans-serif;
}
.connectContainer .helpBtn[data-v-1cc0c864] {
  float: right;
  cursor: pointer;
  color: black;
  margin-left: 1rem;
}
.connectContainer .helpBtn[data-v-1cc0c864]:hover {
  color: black;
}
header[data-v-1cc0c864] {
  text-align: center;
  margin-bottom: 2em;
}
h3[data-v-1cc0c864] {
  margin: 0 0 0.75em 0;
}
.switch[data-v-1cc0c864] {
  font-size: 0.9em;
  text-align: center;
  margin-top: 0.5em;
}
.row[data-v-1cc0c864] {
  display: flex;
  margin: 0.25em 0;
}
label[data-v-1cc0c864] {
  width: 30%;
  display: flex;
  align-items: center;
}
.input[data-v-1cc0c864] {
  flex-grow: 1;
  display: flex;
}
.input input[data-v-1cc0c864] {
  flex-grow: 1;
}
input[data-v-1cc0c864] {
  padding: 0.3em;
}
input[data-v-1cc0c864], button[data-v-1cc0c864] {
  margin: 3px;
}
.connectBtn[data-v-1cc0c864] {
  width: 100%;
  margin: 0;
  margin-top: 0.5em;
  padding: 5px 1.33em 5px 0;
}
.fa-spinner[data-v-1cc0c864] {
  visibility: hidden;
}
.loading .fa-spinner[data-v-1cc0c864] {
  visibility: visible;
}
.loading .fa-openid[data-v-1cc0c864] {
  display: none;
}
.loading .fa-user-slash[data-v-1cc0c864] {
  display: none;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
