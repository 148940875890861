var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "connectPage" },
    [
      _c("Logo"),
      _c(
        "div",
        { staticClass: "connectContainer" },
        [
          _c(
            "div",
            {
              staticClass: "helpBtn",
              attrs: { title: "Show help" },
              on: { click: _vm.showHelp },
            },
            [_c("i", { staticClass: "fas fa-question fa-fw" }), _vm._v(" Help")]
          ),
          _vm.httpsUrl
            ? _c("div", { staticClass: "message error" }, [
                _c("i", { staticClass: "fas fa-shield-alt" }),
                _c("span", [
                  _vm._v("You are using an "),
                  _c("strong", [_vm._v("insecure")]),
                  _vm._v(
                    " HTTP connection, which is not encrypted. Please use HTTPS if possible."
                  ),
                  _c("br"),
                  _c("a", { attrs: { href: _vm.httpsUrl } }, [
                    _vm._v(
                      "Click here to switch to a secured HTTPS connection."
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.message
            ? _c("div", { staticClass: "message warning" }, [
                _c("i", { staticClass: "fas fa-bullhorn" }),
                _c("span", { domProps: { innerHTML: _vm._s(_vm.message) } }),
              ])
            : _vm._e(),
          _c(
            "transition",
            {
              attrs: { name: "connect-fade", mode: "out-in" },
              on: { "before-enter": _vm.initProviders },
            },
            [
              _vm.showConnectForm
                ? _c(
                    "form",
                    {
                      staticClass: "connect",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submitForm.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("h3", [_vm._v("Connect to server")]),
                      _c("div", { staticClass: "row" }, [
                        _c("label", { attrs: { for: "serverUrl" } }, [
                          _vm._v("URL:"),
                        ]),
                        _c("div", { staticClass: "input" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.lazy.trim",
                                value: _vm.serverUrl,
                                expression: "serverUrl",
                                modifiers: { lazy: true, trim: true },
                              },
                            ],
                            staticClass: "tour-connect-url",
                            attrs: {
                              type: "text",
                              id: "serverUrl",
                              disabled: _vm.autoConnect,
                            },
                            domProps: { value: _vm.serverUrl },
                            on: {
                              change: function ($event) {
                                _vm.serverUrl = $event.target.value.trim()
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                          _vm.allowOtherServers
                            ? _c(
                                "button",
                                {
                                  staticClass: "tour-connect-history",
                                  attrs: {
                                    type: "button",
                                    title: "Select previously used server",
                                  },
                                  on: { click: _vm.showServerSelector },
                                },
                                [_c("i", { staticClass: "fas fa-book" })]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "button",
                          {
                            staticClass: "connectBtn tour-connect-retry",
                            class: { loading: _vm.loading },
                            attrs: { type: "submit" },
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-spinner fa-spin fa-lg",
                            }),
                            _vm._v(" Connect"),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _vm.showLoginForm
                ? _c(
                    "div",
                    { staticClass: "login" },
                    [
                      _c("h3", [_vm._v("Log in to " + _vm._s(_vm.title))]),
                      _c("Tabs", {
                        ref: "providers",
                        attrs: {
                          id: "credentials",
                          pills: true,
                          pillsMultiline: true,
                        },
                        on: { selected: _vm.providerSelected },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "dynamic",
                              fn: function ({ tab }) {
                                return [
                                  _c(
                                    "form",
                                    {
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.initDiscovery(tab.data)
                                        },
                                      },
                                    },
                                    [
                                      tab.data.description
                                        ? _c(
                                            "div",
                                            { staticClass: "row help" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-info-circle",
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(tab.data.description)
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.hasPredefinedOidcClientId
                                        ? [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "label",
                                                { attrs: { for: "password" } },
                                                [_vm._v("Client ID:")]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.trim",
                                                    value: _vm.userOidcClientId,
                                                    expression:
                                                      "userOidcClientId",
                                                    modifiers: { trim: true },
                                                  },
                                                ],
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  required: "required",
                                                },
                                                domProps: {
                                                  value: _vm.userOidcClientId,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.userOidcClientId =
                                                      $event.target.value.trim()
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                              }),
                                            ]),
                                            _c(
                                              "div",
                                              { staticClass: "row help" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-exclamation-circle",
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    "You need to specify the "
                                                  ),
                                                  _c("em", [
                                                    _vm._v("Client ID"),
                                                  ]),
                                                  _vm._v(
                                                    " provided to you by the provider. You need to allow the "
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: _vm.oidcRedirectUrl,
                                                        target: "_blank",
                                                        title:
                                                          _vm.oidcRedirectUrl,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "URL of this service"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " as redirect URL with the authentication service."
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "row bottom" },
                                        [
                                          _c("TermsOfServiceConsent"),
                                          _c(
                                            "div",
                                            { staticClass: "row help" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-window-restore",
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  "Clicking the button below may open a new window for login."
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "connectBtn",
                                              class: { loading: _vm.loading },
                                              attrs: { type: "submit" },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-spinner fa-spin fa-lg",
                                              }),
                                              _c("i", {
                                                staticClass: "fab fa-openid",
                                              }),
                                              _vm._v(
                                                " Log in with " +
                                                  _vm._s(tab.name)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              },
                            },
                            _vm.basicProvider
                              ? {
                                  key: "basic",
                                  fn: function ({ tab }) {
                                    return [
                                      _c(
                                        "form",
                                        {
                                          on: {
                                            submit: function ($event) {
                                              $event.preventDefault()
                                              return _vm.initDiscovery(tab.data)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "row help" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-info-circle",
                                              }),
                                              _c(
                                                "span",
                                                [
                                                  _vm._v("The "),
                                                  _c("tt", [
                                                    _vm._v("HTTP Basic"),
                                                  ]),
                                                  _vm._v(
                                                    " authentication method is mostly used for development and testing purposes."
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "label",
                                              { attrs: { for: "username" } },
                                              [_vm._v("Username:")]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model.trim",
                                                  value: _vm.username,
                                                  expression: "username",
                                                  modifiers: { trim: true },
                                                },
                                              ],
                                              staticClass: "input",
                                              attrs: {
                                                id: "username",
                                                type: "text",
                                                required: "required",
                                              },
                                              domProps: { value: _vm.username },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.username =
                                                    $event.target.value.trim()
                                                },
                                                blur: function ($event) {
                                                  return _vm.$forceUpdate()
                                                },
                                              },
                                            }),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "label",
                                              { attrs: { for: "password" } },
                                              [_vm._v("Password:")]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model.trim",
                                                  value: _vm.password,
                                                  expression: "password",
                                                  modifiers: { trim: true },
                                                },
                                              ],
                                              staticClass: "input",
                                              attrs: {
                                                id: "password",
                                                type: "password",
                                                required: "required",
                                              },
                                              domProps: { value: _vm.password },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.password =
                                                    $event.target.value.trim()
                                                },
                                                blur: function ($event) {
                                                  return _vm.$forceUpdate()
                                                },
                                              },
                                            }),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "row bottom" },
                                            [
                                              _c("TermsOfServiceConsent"),
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "connectBtn",
                                                  class: {
                                                    loading: _vm.loading,
                                                  },
                                                  attrs: { type: "submit" },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fas fa-spinner fa-spin fa-lg",
                                                  }),
                                                  _vm._v(" Log in"),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                }
                              : null,
                            {
                              key: "noauth",
                              fn: function () {
                                return [
                                  _c(
                                    "form",
                                    {
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.initDiscovery()
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "row help" }, [
                                        _c("i", {
                                          staticClass: "fas fa-info-circle",
                                        }),
                                        _c("span", [
                                          _vm._v(
                                            "Choose this if you don't have credentials for the service provider and just want to explore the service with its available data and processes. You may not be able to process any data."
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "row bottom" },
                                        [
                                          _c("TermsOfServiceConsent"),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "connectBtn",
                                              class: { loading: _vm.loading },
                                              attrs: { type: "submit" },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-spinner fa-spin fa-lg",
                                              }),
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-user-slash",
                                              }),
                                              _vm._v(
                                                " Proceed without logging in"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm.allowOtherServers
                        ? _c(
                            "div",
                            { staticClass: "switch tour-login-switch" },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.switchServer()
                                    },
                                  },
                                },
                                [_vm._v("Switch server")]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }