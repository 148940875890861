var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "logo" }, [
    _vm.$config.logo
      ? _c("img", {
          attrs: { src: _vm.$config.logo, alt: _vm.$config.serviceName },
        })
      : _vm._e(),
    _c("h2", [
      _vm._v(_vm._s(_vm.$config.appName) + " "),
      _c(
        "span",
        { staticClass: "version", on: { click: _vm.showWebEditorInfo } },
        [_vm._v(_vm._s(_vm.version))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }